import React from "react";
import { GatsbyBrowser, Script } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/mdb.min.css";
import "../css/style.css";
import "../css/styleAdmin.css";

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <>
      <Script
        src="https://kit.fontawesome.com/9bbc75086e.js"
        crossOrigin="anonymous"
      ></Script>
      {element}
    </>
  );
};

export { wrapRootElement };
